import { isNumber } from 'lodash';
import { Nullable, UnixTimeMs } from '../types';

// Don't reorder, must match 0..11 to Jan..Dec
const MONTHS = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

/**
 * e.g. "1 May 21", "21 Sep 98"
 *
 * This is used on some hot paths of our application. Please don't
 * refactor without measuring the performance impact.
 */
export function shortDateFormat(date: Readonly<Date>) {
  const year = date.getFullYear().toString();
  const twoDigitYear = year.slice(-2);
  const month = MONTHS[date.getMonth()];
  return `${date.getDate()} ${month} ${twoDigitYear}`;
}

/**
 * e.g. "01MAY21", "21SEP98"
 *
 * @param date Instance of Date
 * @returns A string with a format that is consumed by the API
 */
export function apiDateFormat(date: Readonly<Date>) {
  const year = date.getFullYear().toString();
  const twoDigitYear = year.slice(-2);
  const day = date.getDate();
  const twoDigitDay = day >= 10 ? day : `0${day}`;
  const month = MONTHS[date.getMonth()]?.toUpperCase();
  return `${twoDigitDay}${month}${twoDigitYear}`;
}

export function hourFormat(date: Date) {
  return `${date.getHours()}:${date.getMinutes().toString().padStart(2, '0')}`;
}

export function dateTimeFormat(date: Date) {
  return `${shortDateFormat(date)} ${hourFormat(date)}`;
}

/**
 * Create a new date object in which the local date is the same
 * as the UTC date from `utcDate` and the time is the beginning
 * of the day in the local time.
 *
 * @example
 *  const date = new Date(1622046792282); // 2021-05-26T16:47:28.014Z (UTC)
 *  utcDateToLocal(date); // Local datetime is 2021-05-26 00:00 regardless of the timezone the user is in.
 */
export function utcStartOfDay(utcDate: Date): Date {
  const localDate = new Date(
    utcDate.getUTCFullYear(),
    utcDate.getUTCMonth(),
    utcDate.getUTCDate(),
  );
  return localDate;
}

export function isSameDayDate(date1: number | Date, date2: number | Date) {
  const dateObj1 = new Date(date1);
  const dateObj2 = new Date(date2);

  return (
    dateObj1.getFullYear() === dateObj2.getFullYear() &&
    dateObj1.getMonth() === dateObj2.getMonth() &&
    dateObj1.getDate() === dateObj2.getDate()
  );
}

/**
 * Convert unix milliseconds to Date
 */
export function msToDate(milliseconds: UnixTimeMs): Date {
  return new Date(milliseconds);
}

/**
 * Convert Date to unix milliseconds without timezone offset
 * @param date date to convert to UTC
 * @returns
 */
export function getUtcDate(date?: Nullable<Date | number>): Nullable<Date> {
  if (date == null) return null;
  const dateObj = isNumber(date) ? new Date(date) : date;
  return new Date(dateObj.getTime() - dateObj.getTimezoneOffset() * 60000);
}
