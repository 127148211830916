import {
  FEATURE_UM_LEG_PRICING_FALLBACK,
  FEATURE_UM_UI_OB_V1_API,
} from '@paradigm/utils/src/constants';
import { useFeatureFlagStore } from './store';

/**
 * Sometimes we need to access state in a non-reactive way.
 */
const getFeatureFlagNonReactive = (flag: string) =>
  useFeatureFlagStore.getState().flags[flag];

/**
 * Retrieves a feature flag state outside of React.
 */
export const isFeatureFlagEnabled = (flag: string, defaultValue = false) => {
  const featureFlag = getFeatureFlagNonReactive(flag);
  return featureFlag?.isEnabled ?? defaultValue;
};

export const isOBV1APIEnabled = () =>
  isFeatureFlagEnabled(FEATURE_UM_UI_OB_V1_API);

export const isLegPricingFallbackEnabled = () =>
  isFeatureFlagEnabled(FEATURE_UM_LEG_PRICING_FALLBACK);

export const featureFlagValue = (flag: string, defaultValue = false) => {
  const featureFlag = getFeatureFlagNonReactive(flag);
  return featureFlag?.value ?? defaultValue;
};
