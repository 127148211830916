import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { ROOT_ROUTE } from '#/routes';

function SetPasswordSuccess() {
  return (
    <Container>
      <h4 css="font-size: 21px; font-weight: 500; margin-bottom: 12px">
        Password updated!
      </h4>
      <p css="font-size: 14px; line-height: 17px; color: #A3D9EA; margin-bottom: 24px">
        Your password has been changed successfully. Please use your new
        password and your username to{' '}
        <Link replace to={ROOT_ROUTE} css="color: white">
          log in to Paradigm
        </Link>
        .
      </p>
      <Button href={ROOT_ROUTE}>Access Paradigm App</Button>
    </Container>
  );
}

export default SetPasswordSuccess;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 400px;
  margin-top: 8px;
`;

const Button = styled.a`
  display: flex;
  align-items: center;
  background: #72adf2;
  color: white;
  font-size: 12px;
  border: 0;
  border-radius: 15px;
  height: 30px;
  padding: 0 18px;
  text-decoration: none;
  transition: color 120ms;

  &:hover {
    cursor: pointer;
    text-decoration: none;
    color: black;
  }
`;
