import { isBefore } from 'date-fns';
import {
  Drfq,
  DrfqLeg,
  RawDfrqLeg,
  isTaker,
} from '#/unified-rfqs/entities/drfq/domain';
import {
  RawBbo,
  RawDrfq,
  RawInstrument,
  RawOrder,
  RawTrade,
  RawTradeTapeItem,
  RawTradeTapeLeg,
  RawWsBbo,
} from '#/unified-rfqs/repositories/drfq-types';
import {
  Trade,
  TradeTapeItem,
  TradeTapeLeg,
} from '#/unified-rfqs/entities/drfq/trades';
import { Order } from '#/unified-rfqs/entities/drfq/orders';
import { Instrument } from '#/unified-rfqs/entities/drfq/instruments';
import { Bbo, BboLeg } from '#/unified-rfqs/entities/drfq/bbo';
import { convertToBigNumber } from '@paradigm/utils/formatDecimal';
import { findDrfqBaseLeg } from '#/unified-rfqs/utils/legs';
import { getOppositeSide } from '#/unified-rfqs/entities/shared';

import { processDisplayValues } from '#/unified-rfqs/utils/trade-display-values';
import { REPRICE_TIMEOUT_MS } from '../../entities/drfq/domain';

export function processDrfqLeg(leg: RawDfrqLeg): DrfqLeg {
  return {
    ...leg,
    isHedge: leg.price != null,
  };
}

export function processDrfqTadeTapeLeg(leg: RawTradeTapeLeg): TradeTapeLeg {
  return {
    ...leg,
    isHedge: leg.price != null,
  };
}

function getDrfqState(rawDrfq: RawDrfq): Drfq['state'] {
  if (rawDrfq.state === 'OPEN') return 'OPEN';

  const repriceExpiresAt = rawDrfq.last_updated_at + REPRICE_TIMEOUT_MS;
  const isRecentlyClosed = isBefore(Date.now(), repriceExpiresAt);

  if (isRecentlyClosed) {
    return 'RECENTLY_CLOSED';
  }

  return 'CLOSED';
}
export function processDrfq(rawDrfq: RawDrfq): Drfq {
  return {
    ...rawDrfq,
    type: 'drfq',
    state: getDrfqState(rawDrfq),
    created_at: new Date(rawDrfq.created_at),
    expires_at: new Date(rawDrfq.expires_at),
    last_updated_at: new Date(rawDrfq.last_updated_at),
    legs: rawDrfq.legs.map(processDrfqLeg),
  };
}

export function processDrfqTrade(rawTrade: RawTrade): Trade {
  const productCode = findDrfqBaseLeg(
    rawTrade.kind,
    rawTrade.legs,
  )?.product_code;

  const { side } = rawTrade;
  const makerSide = isTaker(rawTrade) ? getOppositeSide(side) : side;

  return {
    ...rawTrade,
    rfqType: 'drfq',
    executed_at: new Date(rawTrade.executed_at),
    filled_at: new Date(rawTrade.filled_at),
    displayValues: processDisplayValues(
      rawTrade.price,
      rawTrade.mark_price,
      rawTrade.index_price,
      makerSide,
      rawTrade.description,
      productCode,
    ),
  };
}

export function processDrfqTradeTape(
  rawTradeTape: RawTradeTapeItem,
): TradeTapeItem {
  const productCode = findDrfqBaseLeg(
    rawTradeTape.kind,
    rawTradeTape.legs,
  )?.product_code;
  return {
    ...rawTradeTape,
    rfqType: 'drfq',
    executed_at: new Date(rawTradeTape.executed_at),
    filled_at: new Date(rawTradeTape.filled_at),
    legs: rawTradeTape.legs.map(processDrfqTadeTapeLeg),
    displayValues: processDisplayValues(
      rawTradeTape.price,
      rawTradeTape.mark_price,
      rawTradeTape.index_price,
      // Trade tape resolves Side from Taker's perspective, flip to get Maker's.
      getOppositeSide(rawTradeTape.side),
      rawTradeTape.description,
      productCode,
    ),
  };
}

export function processDrfqOrder(rawOrder: RawOrder): Order {
  return {
    ...rawOrder,
    rfqType: 'drfq',
    created_at: new Date(rawOrder.created_at),
    last_updated_at: new Date(rawOrder.last_updated_at),
  };
}

export function processDrfqInstrument(
  rawInstrument: RawInstrument,
): Instrument {
  return {
    ...rawInstrument,
    created_at: new Date(rawInstrument.created_at),
    expires_at:
      rawInstrument.expires_at === null
        ? rawInstrument.expires_at
        : new Date(rawInstrument.expires_at),
  };
}

export function processDrfqWsBbo(rawBbo: RawWsBbo): Bbo {
  return {
    ...rawBbo,
    best_ask: {
      price: rawBbo.best_ask_price,
      quantity: rawBbo.best_ask_amount,
    },
    best_bid: {
      price: rawBbo.best_bid_price,
      quantity: rawBbo.best_bid_amount,
    },
    legs: rawBbo.legs.map(processDrfqBboLeg),
    created_at: new Date(rawBbo.created_at),
  };
}

function processIvValues(str?: string | null, precision = 2): string {
  if (str == null || str === '') return '';
  const numericValue = convertToBigNumber(str);
  if (numericValue.isEqualTo(0)) return '';
  return numericValue.toFixed(precision);
}
function processDrfqBboLeg(leg: BboLeg): BboLeg {
  return {
    ...leg,
    mark_price_iv: processIvValues(leg.mark_price_iv),
    best_ask_iv: processIvValues(leg.best_ask_iv),
    best_bid_iv: processIvValues(leg.best_bid_iv),
  };
}

export function processDrfqBbo(rawBbo: RawBbo): Bbo {
  return {
    ...rawBbo,
    best_ask: {
      price: rawBbo.best_ask_price,
      quantity: rawBbo.best_ask_quantity,
    },
    best_bid: {
      price: rawBbo.best_bid_price,
      quantity: rawBbo.best_bid_quantity,
    },
    legs: rawBbo.legs.map(processDrfqBboLeg),
  };
}
