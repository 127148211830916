export const ROOT_ROUTE = '/';
export const MAIN_ROUTE = '/main/';
export const MDRFQ_ROUTE = '/mdrfq';
export const BLOTTER_ROUTE = '/blotter';
export const BLOTTER_GRFQ_ROUTE = '/blotter/grfqs';
export const BLOTTER_FUTURE_SPREADS_ROUTE = '/blotter/future-spreads';
export const GRFQS_ROUTE = '/grfqs';
export const VRFQS_ROUTE = '/vrfqs';
export const UNIFIED_RFQS_ROUTE = '/rfqs';
export const FS_ROUTE = '/fs';
export const LEADERBOARD_ROUTE = '/leaderboard';
export const TWO_FA_ROUTE = '/twoFA/';
export const PARADEX_ROUTE = '/paradex';
export const PARADEX_VAULTS_EXTERNAL = 'https://app.paradex.trade/vaults';
