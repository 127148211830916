import { SVGProps } from 'react';
import { paradigmLightBlue } from '@paradigm/design-system/src/colors';

export default function ArrowDownIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="8.0804528px"
      height="4.7902264px"
      viewBox="0 0 8.0804528 4.7902264"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g
          transform="translate(-86.959774, -715.104887)"
          stroke={paradigmLightBlue}
          strokeWidth="1.5"
        >
          <g id="Group-22" transform="translate(49.000000, 300.000000)">
            <g id="Group-27" transform="translate(0.000000, 143.500000)">
              <g
                id="icon-/-expand"
                transform="translate(35.000000, 267.000000)"
              >
                <polyline
                  transform="translate(7.000000, 7.000000) scale(1, -1) rotate(-180.000000) translate(-7.000000, -7.000000) "
                  points="10.2902264 5.3548868 7 8.6451132 3.7097736 5.3548868"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
