import { format, parse } from 'date-fns';

import BigNumber from 'bignumber.js';
import { Draft } from '@reduxjs/toolkit';
import formatDecimal, {
  isValidDecimal,
  parseFormattedFloat,
  parseInputNumber,
  removeTrailingZeroes,
  roundToMultiplier,
} from '@paradigm/utils/formatDecimal';
import {
  GrfqHedgeLeg,
  GrfqLeg,
  GrfqRegularLeg,
} from '#/unified-rfqs/entities/grfq/domain';

import { getProduct } from '#/products/domain';
import { findBaseLegIndex } from '#/unified-rfqs/utils/legs';
import { State } from '#/unified-rfqs/ui/rfq-builder/store';

export function toContractQuantityDisplay(
  leg: GrfqLeg,
  strategyQtyInput: string,
): string {
  const strategyQty = parseInputNumber(strategyQtyInput);
  if (strategyQty == null) return '—';

  if (leg.isHedge) {
    const hedgeContractQty = calculateHedgeLegContractQty(
      leg as GrfqHedgeLeg,
      strategyQty,
    );
    return formatHedgeQty(hedgeContractQty);
  }

  const nonHedgeContractQty = calculateNonHedgeLegContractQty(
    leg as GrfqRegularLeg,
    strategyQty,
  );
  return formatNonHedgeQty(nonHedgeContractQty);
}

export function toContractSizeDisplay(
  leg: GrfqLeg,
  strategyQtyInput: string,
): string {
  const strategyQty = parseInputNumber(strategyQtyInput);
  if (strategyQty == null) return '—';

  const product = getProduct(leg.product_code);

  if (leg.isHedge) {
    const hedgeContractQty = calculateHedgeLegContractQty(
      leg as GrfqHedgeLeg,
      strategyQty,
    );
    const hedgeCurrencyQty = hedgeContractQty / product.contractSize;
    return formatHedgeQty(hedgeCurrencyQty);
  }

  const nonHedgeContractQty = calculateNonHedgeLegContractQty(
    leg as GrfqRegularLeg,
    strategyQty,
  );
  const nonHedgeCurrencyQty = nonHedgeContractQty / product.contractSize;
  return formatNonHedgeQty(nonHedgeCurrencyQty);
}

function calculateNonHedgeLegContractQty(
  leg: GrfqRegularLeg,
  strategyQty: number,
): number {
  const legRatio = Number.parseFloat(leg.ratio);
  const legProduct = getProduct(leg.product_code);

  const contractQty = normalizeLegQty(strategyQty, legRatio);

  // Rounds strategy quantity to the nearest contract increment
  return roundToMultiplier(contractQty, legProduct.minContractIncrement);
}

function calculateHedgeLegContractQty(
  leg: GrfqHedgeLeg,
  strategyQty: number,
): number {
  const legRatio = Number.parseFloat(leg.ratio);
  const legPrice = Number.parseFloat(leg.price);
  const legProduct = getProduct(leg.product_code);

  const normalizedQty = normalizeLegQty(strategyQty, legRatio);
  const contractQty = normalizedQty * legPrice;
  const multiplier = legProduct.minContractIncrement * legProduct.contractSize;

  return roundToMultiplier(contractQty, multiplier);
}

function formatNonHedgeQty(value: number): string {
  return formatDecimal(removeTrailingZeroes(value.toFixed(4)));
}

function formatHedgeQty(value: number): string {
  return formatDecimal(value.toString());
}

/**
 * Converts strategy quantity to leg quantity using the leg ratio.
 * Also prepares it for visualization by making it absolute.
 */
const normalizeLegQty = (strategyQty: number, legRatio: number): number => {
  return Math.abs(strategyQty * legRatio);
};

const INSTRUMENT_SEPARATOR = '-';
const INSTRUMENT_CURRENCY_SEPARATOR = '_';
const INSTRUMENT_STRIKE_DECIMAL_CHAR = 'd';

/**
 * This function extracts currency, expire date, strike and kind of instruments.
 *
 * Instrument name format:
 *  - Inverse products:
 *    - ETH-5MAY21-3500-P
 *    - BTC-1DEC21-80000
 *    - BTC-21DEC21
 *    - BTM-1DEC21
 *    - ETH-USD-31MAR23-900-P
 *    - ETH-31MAR23-900-P-USD-USD-ETH
 *  - Linear products:
 *    - XRP_USDC-11APR24-0d552-C
 *    - MATIC_USDC-11APR24-0d552-C
 */
export function parseInstrument(instrument: string) {
  let currency;
  let quoteCurrency;
  let rawExpiry;
  let rawStrike;
  let optionKind;

  const instrument_parts = instrument
    ? instrument.split(INSTRUMENT_SEPARATOR)
    : [];

  switch (true) {
    case instrument_parts.length > 4:
      [currency, rawExpiry = '', rawStrike, optionKind] =
        instrument_parts.slice(0, -3);
      break;
    default:
      [currency = '', rawExpiry = '', rawStrike, optionKind] = instrument_parts;
      break;
  }

  const strike =
    rawStrike === 'F'
      ? '-'
      : rawStrike?.replace(INSTRUMENT_STRIKE_DECIMAL_CHAR, '.');

  const expiry =
    rawExpiry === 'PERPETUAL'
      ? 'Perpetual'
      : format(parse(rawExpiry, 'dMMMyy', Date.now()), 'dd MMM yy');

  const instrumentLabel =
    optionKind === 'P' ? 'Put' : optionKind === 'C' ? 'Call' : 'Future';

  if (currency?.includes(INSTRUMENT_CURRENCY_SEPARATOR) === true) {
    [currency, quoteCurrency] = currency.split(INSTRUMENT_CURRENCY_SEPARATOR);
  }

  return {
    currency,
    quoteCurrency,
    expiry,
    strike,
    instrumentLabel,
  };
}

/**
 * Finds the base leg and update other legs ratios according to
 * the base leg ratio.
 *
 * @param state - Current form state
 * @returns void
 */
export function updateCustomStratRatios(state: Draft<State>) {
  const baseLeg = selectBaseLeg(state);
  if (baseLeg?.quantity == null) return;

  baseLeg.ratio = '1';
  for (const leg of state.legs) {
    if (leg.index === baseLeg.index) continue;
    if (leg.quantity == null || !isValidDecimal(leg.quantity)) continue;

    leg.ratio = BigNumber(parseFormattedFloat(leg.quantity))
      .dividedBy(parseFormattedFloat(baseLeg.quantity))
      .toString();
  }
}

export function selectBaseLeg(state: Draft<State>) {
  const index = findBaseLegIndex(state.legs);
  if (index === -1) return null;

  const baseLeg = state.legs[index];

  return baseLeg;
}

export function hasNonIntegerRatio(legs: readonly { ratio: string }[]) {
  return legs.some((leg) => !BigNumber(leg.ratio).isInteger());
}
