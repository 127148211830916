import { ReactNode, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import * as Toastify from 'react-toastify';

import { nepal, ripeLemon, mirage } from '@paradigm/design-system/src/colors';
import Close from '@paradigm/design-system/src/assets/CloseIcon';
import useIsMobile from '@paradigm/design-system/src/hooks/useIsMobile';

import { warningYellowIcon } from '#/assets';
import { getIcon } from './utils';
import LegacyToastIcon from './LegacyToastIcon';

interface ToastStyles {
  readonly alignItems: string;
  readonly minWidth: string | undefined;
  readonly maxWidth: string | undefined;
  readonly backgroundColor: string;
  readonly borderColor: string;
  readonly color: string;
  readonly padding?: string;
  readonly content?: {
    readonly margin: string;
  };
}

// Our base react-toastify is configured to have no styles.
// This is the main theme for common toasts.
const DEFAULT_STYLES: ToastStyles = {
  alignItems: 'center',
  backgroundColor: mirage,
  borderColor: ripeLemon,
  color: nepal,
  padding: '0.5rem 1rem',
  minWidth: '400px',
  maxWidth: '400px',
  content: {
    margin: 'auto 0.875rem',
  },
};

const ToastContent = styled.div<{ styles: ToastStyles }>(
  ({ styles }) => `
  justify-content: space-between;
  align-items: ${styles.alignItems};
  background-color: ${styles.backgroundColor};
  border-radius: 0.5rem;
  border: 1px solid ${styles.borderColor};
  min-width: ${styles.minWidth};
  max-width: ${styles.maxWidth};
  color: ${styles.color};
  display: flex;
  min-height: 56px;
  padding: ${styles.padding};
  font-family: 'IBM Plex Sans', sans-serif;
`,
);

const ToastContentBody = styled.div<{ styles: ToastStyles }>(
  ({ styles }) => `
    max-width: 80%;
    margin: ${styles.content?.margin};
`,
);

const BodyContainer = styled.div<{ styles: ToastStyles }>(
  ({ styles }) => `
  display: flex;
  justify-content: flex-start;
  align-items: ${styles.alignItems};
  flex: 1;
`,
);

interface ToastProps extends Toastify.ToastContentProps {
  readonly icon: Toastify.ToastItem['icon'];
  readonly children: ReactNode;
  readonly styles?: Partial<ToastStyles>;
  readonly noClose?: boolean;
}

// This is a common toast. Used so that we have full control of Toast content.
function Toast(props: ToastProps) {
  const { closeToast, toastProps, styles, children, noClose = false } = props;
  const icon = getIcon({ ...toastProps, icon: props.icon });
  const isMobile = useIsMobile();

  const toastStyles: ToastStyles = useMemo(
    () => ({
      ...DEFAULT_STYLES,
      ...(isMobile ? { minWidth: undefined, maxWidth: undefined } : {}),
      ...styles,
    }),
    [isMobile],
  );

  useEffect(() => {
    const handleKeyUp = (event: KeyboardEvent) => {
      if (noClose) return;
      if (event.key !== 'Escape') return;
      event.preventDefault();
      event.stopImmediatePropagation();
      Toastify.toast.dismiss();
    };
    const options = { capture: true };
    document.addEventListener('keyup', handleKeyUp, options);
    return () => document.removeEventListener('keyup', handleKeyUp, options);
  }, [noClose]);

  return (
    <ToastContent
      styles={toastStyles}
      data-testid="toast-notification-container"
    >
      <BodyContainer styles={toastStyles}>
        {icon}
        <ToastContentBody styles={toastStyles}>{children}</ToastContentBody>
      </BodyContainer>
      {!noClose && (
        <IconContainer>
          <CloseIcon onClick={closeToast} />
        </IconContainer>
      )}
    </ToastContent>
  );
}

export type ToastContent = Toastify.ToastContent;

export interface ToastOptions extends Toastify.ToastOptions {
  readonly styles?: Partial<ToastStyles>;
  readonly noClose?: boolean;
}

export default function toast(
  content: ToastContent,
  options: ToastOptions = {},
) {
  // Subset of params are stripped from Toastify options to hand control
  // over to the custom React surface.
  const {
    icon = <LegacyToastIcon src={warningYellowIcon} />,
    noClose,
    styles,
    containerId = 'default',
    ...toastifyOpts
  } = options;

  Toastify.toast(
    (props) => (
      <Toast {...props} icon={icon} noClose={noClose} styles={styles}>
        {typeof content === 'function' ? content(props) : content}
      </Toast>
    ),
    { ...toastifyOpts, containerId },
  );
}

const withoutAnimation = Toastify.cssTransition({
  collapse: false,
  enter: 'without-animation',
  exit: 'without-animation',
});

type Position =
  | 'top-right'
  | 'top-center'
  | 'top-left'
  | 'bottom-right'
  | 'bottom-center'
  | 'bottom-left';

interface ToastContainerProps extends Toastify.ToastContainerProps {
  position?: Position;
  containerId?: string;
  draggable?: boolean;
}

export const ToastContainer: React.FC<ToastContainerProps> = ({
  position = 'top-center',
  containerId = 'default',
  draggable = false,
  ...props
}) => (
  <Toastify.ToastContainer
    hideProgressBar
    position={position}
    closeOnClick={false}
    autoClose={false}
    closeButton={false}
    transition={withoutAnimation}
    containerId={containerId}
    draggable={draggable}
    {...props}
  />
);

const CloseIcon = styled(Close)`
  width: 0.625rem;
  color: white;
`;

const IconContainer = styled.div`
  width: 1rem;
`;

export const dismissToast = (toastId: Toastify.Id) =>
  Toastify.toast.dismiss(toastId);
